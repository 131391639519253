import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import "../../../styles/banner.css";
import { imagemURL } from "../../../services/variables";

export default function ControlledCarousel(props) {
  const [index, setIndex] = useState(0);

  const banner = props.objImg;

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <>
      {banner.length !== 0 && (
        <Carousel activeIndex={index} onSelect={handleSelect} touch={true}>
          {banner.map((item, i) => {
            return (
              <Carousel.Item key={i}>
                <img
                  className="d-block w-100-banner"
                  src={imagemURL + item.IMG}
                  alt="Imagem Banner"
                />
                <Carousel.Caption>
                  <a
                    className="btn-banner-link"
                    variant="primary"
                    href={item.LINK}
                  ></a>
                </Carousel.Caption>
              </Carousel.Item>
            );
          })}
          <Carousel.Item>
            <img
              className="d-block w-100-banner"
              src={
                "https://arquivos-api-portais.s3.sa-east-1.amazonaws.com/4b3fbeb96f68cc039425f91dc7033cbfc066e300_8f9de589-595f-4fa4-b87f-67e0a9df9304.jpeg"
              }
              alt="Imagem Banner"
            />
            <Carousel.Caption>
              <a
                className="btn-banner-link"
                variant="primary"
                href={
                  "https://docs.google.com/forms/d/1itHaKI_z6Uxml1u2kDsVYFYmbKLleJf8mTzeCPteX8k/viewform?edit_requested=true"
                }
              ></a>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      )}
    </>
  );
}

// sfawfw8//*/
